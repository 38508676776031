
import { defineComponent, reactive, toRefs } from 'vue'

import router from '@/router'
import { realmApp } from '@/helpers'
import { onEmailConfirmedDynamicLink } from '@/constants'

import Terms from '@/components/atoms/Terms'
import HalfPageHalfImage from '@/components/atoms/HalfPageHalfImage'

export default defineComponent({
  components: {
    Terms,
    HalfPageHalfImage,
  },
  setup() {
    const state = reactive({
      isLoading: true,
      success: false,
    })

    const main = async () => {
      const { token, tokenId, overrideSuccess } = router.currentRoute.value
        .query as Record<string, string>

      if (overrideSuccess === 'true') {
        state.isLoading = false
        state.success = true
      }

      if (!token || !tokenId) {
        state.isLoading = false
        return
      }

      try {
        await realmApp.emailPasswordAuth.confirmUser(token, tokenId)
        state.success = true
        window.location.replace(onEmailConfirmedDynamicLink)
      } catch (error) {
        console.error(error)
      }

      state.isLoading = false
    }

    main()

    return {
      ...toRefs(state),
    }
  },
})
